import _ from "lodash";
//let _ = require("lodash")

function _calculateAngle(x1,y1,x2,y2,x3,y3,x4,y4){
    let dx1 = x2-x1;
    let dy1 = y2-y1;
    let dx2 = x4-x3;
    let dy2 = y4-y3;

    let d = dx1*dx2 + dy1*dy2;   // dot product of the 2 vectors
    let l2 = (dx1*dx1+dy1*dy1)*(dx2*dx2+dy2*dy2) // product of the squared lengths


    let angle = Math.acos(d/Math.sqrt(l2))* 180 / Math.PI;
    console.log("angle",angle)
    return angle
}


function calculateMiddleLine(positionMap){
    let pos1;
    let pos2;
    console.log("calculateMiddleLine", positionMap)
    if(positionMap.leftAnkle && positionMap.rightAnkle){
        let x = (positionMap.leftAnkle.position.x + positionMap.rightAnkle.position.x)/2

        pos1 = {
            x,
            y:positionMap.leftAnkle.position.y
        };

        pos2 = {
            x,
            y:10
        }
    }

    return {
        pos1,pos2
    }

}

function calculateGravityLine(positionMap){
    let pos1;
    let pos2;
    console.log("calculateMiddleLine", positionMap)
    if(positionMap.leftAnkle && positionMap.rightAnkle && positionMap.leftShoulder && positionMap.rightShoulder){
        pos1 = {
            x:(positionMap.leftAnkle.position.x + positionMap.rightAnkle.position.x)/2,
            y:(positionMap.leftAnkle.position.y + positionMap.rightAnkle.position.y)/2
        };

        pos2 = {
            x:(positionMap.leftShoulder.position.x + positionMap.rightShoulder.position.x)/2,
            y:(positionMap.leftShoulder.position.y + positionMap.rightShoulder.position.y)/2
        }
    }

    return {
        pos1,pos2
    }
}

function calculateGravityAngle(middleLinePos,gravityLinePos){
    let angle = _calculateAngle(middleLinePos.pos1.x,middleLinePos.pos1.y,
        middleLinePos.pos2.x,middleLinePos.pos2.y,
        gravityLinePos.pos1.x,gravityLinePos.pos1.y,
        gravityLinePos.pos2.x,gravityLinePos.pos2.y)

    return {
        info:"中心偏移"+angle.toFixed(2)+"°",
        data:{
            angle
        }
    }
}


function calculateHipStandard(positionMap){
    let pos1;
    let pos2;
    if(positionMap.leftHip && positionMap.rightHip){
        pos1 = {
            x:positionMap.rightHip.position.x - 30,
            y:positionMap.rightHip.position.y
        };

        pos2 = {
            x:positionMap.leftHip.position.x + 30,
            y:positionMap.rightHip.position.y
        }
    }

    return {
        pos1,pos2
    }
}

function calculateHipLine(positionMap){
    let pos1;
    let pos2;
    if(positionMap.leftHip && positionMap.rightHip){
        pos1 = {
            x:positionMap.rightHip.position.x,
            y:positionMap.rightHip.position.y
        };

        pos2 = {
            x:positionMap.leftHip.position.x,
            y:positionMap.leftHip.position.y
        }
    }

    return {
        pos1,pos2
    }
}

function calculateHipAngle(hipStandardPos,hipLinePos){
    let angle = _calculateAngle(hipStandardPos.pos1.x,hipStandardPos.pos1.y,
        hipStandardPos.pos2.x,hipStandardPos.pos2.y,
        hipLinePos.pos1.x,hipLinePos.pos1.y,
        hipLinePos.pos2.x,hipLinePos.pos2.y)

    return {
        info:"盆骨偏移"+angle.toFixed(2)+"°",
        data:{
            angle
        }
    }
}


function calculateShoulderStandard(positionMap){
    let pos1;
    let pos2;
    if(positionMap.leftShoulder && positionMap.rightShoulder){
        pos1 = {
            x:positionMap.rightShoulder.position.x - 30,
            y:positionMap.rightShoulder.position.y
        };

        pos2 = {
            x:positionMap.leftShoulder.position.x + 30,
            y:positionMap.rightShoulder.position.y
        }
    }

    return {
        pos1,pos2
    }
}

function calculateShoulderLine(positionMap){
    let pos1;
    let pos2;
    if(positionMap.leftShoulder && positionMap.rightShoulder){
        pos1 = {
            x:positionMap.rightShoulder.position.x,
            y:positionMap.rightShoulder.position.y
        };

        pos2 = {
            x:positionMap.leftShoulder.position.x,
            y:positionMap.leftShoulder.position.y
        }
    }

    return {
        pos1,pos2
    }
}

function calculateShoulderAngle(shoulderStandardPos,shoulderLinePos){
    console.log("sholderStandardPos,sholderLinePos",shoulderStandardPos,shoulderLinePos)
    let angle = _calculateAngle(shoulderStandardPos.pos1.x,shoulderStandardPos.pos1.y,
        shoulderStandardPos.pos2.x,shoulderStandardPos.pos2.y,
        shoulderLinePos.pos1.x,shoulderLinePos.pos1.y,
        shoulderLinePos.pos2.x,shoulderLinePos.pos2.y)

    return {
        info:"肩膀偏移"+angle.toFixed(2)+"°",
        data:{
            angle
        }
    }
}


function calculateBackLineStandard(positionMap){
    let pos1;
    let pos2;
    if(positionMap.rightAnkle){
        pos1 = {
            x:positionMap.rightAnkle.position.x ,
            y:positionMap.rightAnkle.position.y
        };

        pos2 = {
            x:positionMap.rightAnkle.position.x,
            y:20
        }
    }

    return {
        pos1,pos2
    }
}

function calculateNeckLine(positionMap){
    let pos1;
    let pos2;
    if(positionMap.rightEar && positionMap.rightShoulder){
        pos1 = {
            x:positionMap.rightShoulder.position.x ,
            y:positionMap.rightShoulder.position.y
        };

        pos2 = {
            x:positionMap.rightEar.position.x ,
            y:positionMap.rightEar.position.y
        }
    }

    return {
        pos1,pos2
    }
}
function calculateNeckAngle(backStandardLine,neckLine){
    let angle = _calculateAngle(backStandardLine.pos1.x,backStandardLine.pos1.y,
        backStandardLine.pos2.x,backStandardLine.pos2.y,
        neckLine.pos1.x,neckLine.pos1.y,
        neckLine.pos2.x,neckLine.pos2.y)

    return {
        info:"颈部偏移"+angle.toFixed(2)+"°",
        data:{
            angle
        }
    }
}

export async function detect(pose, detectType='front'){
    let positionMap = _.keyBy(pose.keypoints, 'part');

    let lines = [];
    let results = [];

    if(detectType==='front'){

        let middleLinePos = calculateMiddleLine(positionMap)
        lines.push({
            ...middleLinePos,
            color:"#77ccff"
        })

        let gravityLinePos = calculateGravityLine(positionMap)
        lines.push({
            ...gravityLinePos,
            color:"#fff655"
        })

        let gravityResult = calculateGravityAngle(middleLinePos,gravityLinePos);
        results.push(gravityResult)


        let sholderStandardPos = calculateShoulderStandard(positionMap)
        lines.push({
            ...sholderStandardPos,
            color:"#77ccff"
        })

        let sholderLinePos = calculateShoulderLine(positionMap);
        lines.push({
            ...sholderLinePos,
            color:"#fff655"
        })

        let sholderResult = calculateShoulderAngle(sholderStandardPos,sholderLinePos);
        results.push(sholderResult)


        let hipStandardPos = calculateHipStandard(positionMap)
        lines.push({
            ...hipStandardPos,
            color:"#77ccff"
        })

        let hipLinePos = calculateHipLine(positionMap);
        lines.push({
            ...hipLinePos,
            color:"#fff655"
        })

        let hipResult = calculateHipAngle(hipStandardPos,hipLinePos);
        results.push(hipResult)


    }else if(detectType==='side'){
        let backStandardLine = calculateBackLineStandard(positionMap);
        lines.push({
            ...backStandardLine,
            color:"#77ccff"
        })

        let neckLine =calculateNeckLine(positionMap);
        lines.push({
            ...neckLine,
            color:"#fff655"
        })

        let neckResult = calculateNeckAngle(backStandardLine,neckLine)
        results.push(neckResult)
    }

    return{
        lines,
        results
    }
}


// _calculateAngle(0,0, 1,0, 0,0, 1,1)