import React, {Component} from 'react';
import './App.css';
import Webcam from "react-webcam";
import 'react-html5-camera-photo/build/css/index.css';
import {estimateFromBase64} from './module/posenet'
import {detect} from './module/detect'
import _ from 'lodash';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page: "camera",
            photoUri: "",
            detectType: "front",
            results: []
        }


    }
    _drawLine(ctx,start,end,color){
        ctx.strokeStyle=color;
        ctx.beginPath();
        ctx.moveTo(start.x,start.y);
        ctx.lineTo(end.x,end.y);
        ctx.stroke();
    }

    drawLines(lines){

        var canvas = document.getElementById('canvas')
        var ctx = canvas.getContext("2d");

        ctx.lineWidth=2;

        lines.map((line)=>{
            this._drawLine(ctx,line.pos1,line.pos2,line.color)
        })




    }

    drawPoints(pose){
        var canvas = document.getElementById('canvas')
        var ctx = canvas.getContext("2d");
        ctx.fillStyle = "#ff66cc"; // Red color
        for(let i = 0; i<pose.keypoints.length;i++){
            var point = pose.keypoints[i];
            ctx.beginPath();
            ctx.arc(point.position.x, point.position.y, 5 , 0, Math.PI * 2, true);
            ctx.fill();
        }
    }
    async onTakePhoto() {
        // let blob = await this.camera.capture();
        let dataUri  = this.webcam.getScreenshot();;

        this.setState({
            page:"loading",
            photoUri:dataUri
        });

        //setTimeout(()=>{},100)

        let pose = await estimateFromBase64(dataUri);

        let result = await detect(pose,this.state.detectType)

        console.log("DETECT RESULT",result)
        let lines = result.lines;

        this.setState({
            page:"report",
            results:result.results || []
        },()=>{
            setTimeout(()=>{
                let img = new Image();
                img.src = dataUri;
                var canvas = document.getElementById('canvas')
                canvas.width = img.width;      // set canvas size big enough for the image
                canvas.height = img.height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img,0,0);         // draw the image
                this.drawLines(lines)
                console.log(pose)
            },100)
        })





        // console.log(dataUri)

    }

    renderCamaraView(){
        const videoConstraints = {
            width: 640,
            height: 480,
            facingMode: "environment"
        };
        if(this.state.page==='camera'){
            return(<div style={{backgroundColor:"#a0a0a0", maxWidth:"100vw",height:"100vh"}}>
                    <Webcam
                        audio={false}
                        height={""}
                        ref={(webcam)=>{this.webcam = webcam;}}
                        screenshotFormat="image/jpeg"
                        width={""}
                        videoConstraints={videoConstraints}
                        style={{backgroundColor:"#a0a0a0", maxWidth:"100vw"}}
                    />
                    <div style={{position:'absolute',zIndex:100, bottom:"10%",width:"100vw"}} >
                        <div>
                            <div className={this.state.detectType==='front'?'select-pose-button-selected':'select-pose-button'}

                            onClick={()=>{
                                this.setState({
                                    detectType:'front'
                                })
                            }}

                            >正面</div>
                            <div className={this.state.detectType==='side'?'select-pose-button-selected':'select-pose-button'}  onClick={()=>{
                                this.setState({
                                    detectType:'side'
                                })
                            }}>侧面</div>
                        </div>
                        <div style={{margin:"auto", padding:20, borderRadius:5, backgroundColor:"#f0f0f0"}} onClick={this.onTakePhoto.bind(this)} > 拍照 </div>
                    </div>

            </div>)
        }else{
            return null
        }
    }


    renderReportResults(){

    }

    renderReportView(){
        if(this.state.page==='report'){

            return(<div style={{padding:10}}>
                    <div>
                        <canvas id='canvas'></canvas>
                    </div>
                    <div style={{ minHeight:100, backgroundColor:"#f9f9f9",marginTop:10, padding:10}}>
                        <div style={{fontSize:"150%", textAlign:"center", fontWeight:"900"}}>测试结果</div>


                        {(this.state.results.map(r=>{
                            return (<div>{r.info}</div>)
                        }))}

                    </div>
                    <div style={{marginTop:20}}>
                        <a href="#" className="myButton" onClick={()=>{this.setState({page:"camera"})}}>返回</a>
                    </div>
                </div>)



        }else{
            return null
        }
    }

    renderLoading(){
        if(this.state.page==='loading'){

            return(<div style={{}}>
                <div style={{height:"100vh",position:"relative"}}>
                    <img src={this.state.photoUri} className={'loading-image'}/>
                    <div className="lds-spinner">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>)



        }else{
            return null
        }
    }

    render() {
        return (
            <div className="App">
                {this.renderCamaraView()}
                {this.renderLoading()}
                {this.renderReportView()}
            </div>
        );
    }
}

export default App;
